// signals.tsx
import React from 'react';
import { Typography, Stack, Box, Paper, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SignalsPage = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const signalsData = [
        {
            title: "The Cross-Chain Opportunity",
            content: (
                <span>
                    <br></br>
                    <Typography variant="h6" gutterBottom style={{ fontSize: '1.4em' }}>
                        Consider the following facts:
                    </Typography>
                    <br></br>
                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2em' }}>
                        A protocol's value and moat is its <span style={{ color: 'pink' }}>liquidity</span>, not code.
                    </Typography>
                    <br></br>
                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2em' }}>
                        <span style={{ color: 'pink' }}>Liquidity</span> is needed on a new chain to service new demand. Insufficient <span style={{ color: 'pink' }}>liquidity</span> heightens price volatility and slippage, degrading use outcomes.
                    </Typography>
                    <br></br>
                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2em' }}>
                        Protocol growth across chains is easy since contract development and existing demand is proven, but <span style={{ color: 'pink' }}>liquidity</span> for the service cannot be duplicated.
                    </Typography>
                    <br /><br />
                    <Typography variant="body1" gutterBottom style={{ fontSize: '1.2em' }}>
                        <span style={{ color: 'pink' }}> With CCIP, new chain expansions use global liquidity</span>.
                    </Typography>
                    <br /><br />

                    <img src="./cc_opportunity_before.png" alt="cc opp before" style={{ width: '50%', height: 'auto', marginBottom: '0px' }} />
                    <Typography variant="body1" gutterBottom>
                        without interop, growing protocol to new chains is constrained by local liquidity
                    </Typography>
                    <br></br>
                    <img src="./cc_opportunity_after.png" alt="cc opp before" style={{ width: '50%', height: 'auto', marginBottom: '0px' }} />
                    <Typography variant="body1" gutterBottom>
                        using CCIP, chain expansion uses *global* liquidity
                    </Typography>
                    <br /><br />
                    The nature of this investment signal is simple yet universal.
                    Protocol expansion to new chains is always a low risk path to growth,
                    assuming resources can be moved to the new chain securely and easily.
                    The protocol's proven demand and existing codebase facilitates the appearance of new demand.
                    Contrast this with research, developing new contracts,
                    or incentivizing users.
                    <br /><br />

                    Did we mention CCIP makes the protocol resilient to chain-specific congestion and risk?
                    {/* <Divider sx={{ marginY: 4 }} /> */}
                    <br /><br />
                    <br /><br />
                    To quantify the cross-chain opportunity remaining for a protocol, compare the cross-chain volume with intra-chain volume.
                    <br /><br />

                    <Typography variant="h6" gutterBottom>
                        cross-chain ratio = cross-chain volume / intra-chain volume
                    </Typography>
                    <br></br>

                    When cross-chain ratio = 0, protocol liquidity is isolated in individual chains it operates on.
                    No movement of liquidity has been done. As cross-chain volume approaches intra-chain volume,
                    the opportunity is realized. Protocol expansion rate becomes less constrained by availability of liquidity.
                    When cross-chain ratio is equal or greater than 1,
                    protocol usage ignores blockchain boundaries almost like its users belong to the same chain.
                    <br /><br />

                    <img src="./cc_opportunity_prot_zones.png" alt="cc opp zones" style={{ width: '40%', height: 'auto', marginBottom: '0px' }} />
                    <br /><br />
                    This metric remains agnostic to protocol size; the protocol may reallocate the same resources - perhaps from its dominant chain to new points of growth - for a higher cc ratio.
                    <br /><br />
                    {/* <Divider sx={{ marginY: 2 }} /> */}

                </span>
            )
        },
        // {
        //     title: "signs of TradFi onboarding",
        //     content: (
        //         <span>
        //             in progress...

        //         </span>
        //     )
        // },
        // Add more sections here
    ];

    return (
        <Stack sx={{ padding: 4, alignItems: 'center' }}>
            <Button
                variant="text"
                onClick={handleBack}
                sx={{
                    color: 'white',
                    fontSize: '1.1em',
                    marginLeft: '0px',
                    marginRight: '0px',
                }}
            >
                Go Back
            </Button>

            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                CCIP investment and trade signals
            </Typography>
            {signalsData.map((signal, index) => (
                <Box key={index} sx={{ marginBottom: 2 }}>
                    <Paper sx={{ padding: 2, }}>
                        <Typography variant="h3" textAlign="center">{signal.title}</Typography>
                        <Typography component="div" textAlign="center">
                            {signal.content}
                        </Typography>
                    </Paper>
                </Box>
            ))}

        </Stack>
    );
};

export default SignalsPage;
