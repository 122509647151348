import React, { useEffect, useState } from 'react';
import {
    Typography, Stack, Box, Paper, Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel, styled,

} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // If you're using React Router v6
import InstitutionCards from '../components/institutionCards';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip as TextTooltip } from '@mui/material';
import CopyButton from '../utils/CopyButton';
import Papa from 'papaparse';


interface TooltipDict {
    [key: string]: string | undefined;
}

const tooltips: TooltipDict = {
    "ccip revenue": "For a message, flat message fee $0.45. For token transfer, transfered value * bps (0.1%) or $1, whichever is larger. Plus destination gas.",
    "median transaction time(mins)": "time of destination tx - time of source tx. Add source chain confirmation time for total duration.",
    "source-destination": "lanes",
    "median gas fee": "only sending messages or tokens incurr a gas fee and generate ccip revenue",
};

const EntitiesPage = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); // For React Router v6
        // history.goBack(); // Uncomment this line for React Router v5 and earlier
    };

    const [statsData, setStatsData] = useState<Array<any>>([]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        color: theme.palette.text.primary,
    }));

    const [order, setOrder] = useState<'asc' | 'desc'>('desc');  // Set default sort order to 'desc'
    const [orderBy, setOrderBy] = useState<string | null>('ccip revenue');

    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        fetch('/the_stats.csv')
            .then(response => response.text())
            .then(text => {
                const parsed = Papa.parse(text, { header: true });
                setStatsData(parsed.data);
            });
    }, []);

    const sortedData = [...statsData].sort((a, b) => {
        if (!orderBy) return 0;

        const aValue = a[orderBy];
        const bValue = b[orderBy];

        // Handle None (null) values by pushing them to the end of the sorted list
        if (aValue === null) return 1;
        if (bValue === null) return -1;

        if (orderBy === Object.keys(statsData[0])[0]) { // if it's the first column, sort as string
            return (aValue < bValue ? -1 : 1) * (order === 'asc' ? 1 : -1);
        } else { // else, sort as number
            return (parseFloat(aValue) < parseFloat(bValue) ? -1 : 1) * (order === 'asc' ? 1 : -1);
        }
    });

    return (
        <>
            <Typography variant="h4" color="textPrimary" align="center" style={{ marginTop: '100px', marginBottom: '100px' }}>
                Entities
            </Typography>

            <InstitutionCards />

            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px', alignSelf: 'flex-start' }}>
                <Button sx={{ backgroundColor: '#2E2E2E', '&:hover': { backgroundColor: '#434343' } }}
                    variant="contained"> $ </Button>
                <Button sx={{ backgroundColor: '#2E2E2E', '&:hover': { backgroundColor: '#434343' } }}
                    variant="contained" style={{ marginLeft: '10px' }}> all </Button>
            </div>
            <TableContainer component={Paper} elevation={2}>

                <Table>
                    <TableHead>
                        <TableRow>
                            {statsData[0] && Object.keys(statsData[0]).map((key, index) => (
                                <StyledTableCell key={key}>
                                    <Typography color="primary" variant="subtitle1">
                                        {key}
                                        {tooltips[key] && (
                                            <TextTooltip title={tooltips[key]} arrow>
                                                <HelpOutlineIcon style={{ marginLeft: '8px', fontSize: 'small' }} />
                                            </TextTooltip>
                                        )}
                                    </Typography>
                                    {index !== 0 && (
                                        <TableSortLabel
                                            active={orderBy === key}
                                            direction={orderBy === key ? order : 'asc'}
                                            onClick={() => handleSort(key)}
                                        />
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedData.filter(row => row).slice(0).map((row, index) => (
                            <TableRow key={index}>
                                {Object.entries(row).map(([key, cell], index) => (
                                    // COPY ADDRESS BUTTON IN 'address'
                                    <StyledTableCell key={index}>
                                        {key === 'address' ? (
                                            <CopyButton text={cell as string} />
                                        ) : (
                                            cell as React.ReactNode
                                        )}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        </>
    );
};

export default EntitiesPage;