import { alpha } from "@mui/material";
import pxToRem from "../utils/pxToRem";

export const BLUE = "#0073FF";
export const GREEN = "#02b520";
export const RED = "#FF0000";
export const ORANGE = "#FF6D00";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";

/* ===========================>LIGHT Theme Colors<=========================== */
export const TEXT_PRIMARY_LT = "#333333";
export const TEXT_SECONDARY_LT = "#777777";
export const TEXT_DISABLED_LT = "#666666";

export const PRIMARY_LIGHT_LT = "#5CE1E6";
export const PRIMARY_MAIN_LT = "#5CE1E6";
export const PRIMARY_DARK_LT = "#4BE0E6";
export const PRIMARY_CONTRAST_LT = "#ffffff";

export const SECONDARY_LIGHT_LT = "#F5F5F5";
export const SECONDARY_MAIN_LT = "#FFFFFF";
export const SECONDARY_DARK_LT = "#F5F5F5";
export const SECONDARY_CONTRAST_TEXT_LT = "#000000";

export const GREY_50_LT = "#F5F5F5";
export const GREY_100_LT = "#EEEEEE";
export const GREY_200_LT = "#F4F6F8";
export const GREY_300_LT = "#e4e3e6";
export const GREY_400_LT = "#C4C4C4";
export const GREY_500_LT = "#BDBDBD";
export const GREY_600_LT = "#414141";
export const GREY_700_LT = "#454F5B";
export const GREY_800_LT = "#444444";
export const GREY_900_LT = "#161C24";
export const GREY_A100_LT = alpha("#444444", 0.08);
export const GREY_A200_LT = alpha("#444444", 0.16);
export const GREY_A400_LT = alpha("#444444", 0.32);
export const GREY_A700_LT = alpha("#444444", 0.56);

export const DIVIDER_COLOR_LT = "#e4e3e6";

export const INFO_LIGHT_LT = "#4d79ff";
export const INFO_MAIN_LT = "#0073FF";
export const INFO_DARK_LT = "#005eff";
export const INFO_CONTRAST_TEXT_LT = PRIMARY_CONTRAST_LT;

export const SUCCESS_LIGHT_LT = "#7ED957";
export const SUCCESS_MAIN_LT = "#7ED957";
export const SUCCESS_DARK_LT = "#7ED957";
export const SUCCESS_CONTRAST_TEXT_LT = "#ffffff";

export const WARNING_LIGHT_LT = "#EBB6E5";
export const WARNING_MAIN_LT = "#EBB6E5";
export const WARNING_DARK_LT = "#EBB6E5";
export const WARNING_CONTRAST_LT = "#ffffff";

/* ===========================>DARK Theme Colors<=========================== */
export const TEXT_PRIMARY_DT = "#e4e3e6";
export const TEXT_SECONDARY_DT = "#C4C4C4";
export const TEXT_DISABLED_DT = "#666666";

export const GREY_50_DT = "#F5F5F5";
export const GREY_100_DT = "#EEEEEE";
export const GREY_200_DT = "#F4F6F8";
export const GREY_300_DT = "#e4e3e6";
export const GREY_400_DT = "#C4C4C4";
export const GREY_500_DT = "#BDBDBD";
export const GREY_600_DT = "#414141";
export const GREY_700_DT = "#454F5B";
export const GREY_800_DT = "#444444";
export const GREY_900_DT = "#161C24";
export const GREY_A100_DT = alpha("#333333", 0.08);
export const GREY_A200_DT = alpha("#333333", 0.16);
export const GREY_A400_DT = alpha("#333333", 0.32);
export const GREY_A700_DT = alpha("#333333", 0.56);

export const PRIMARY_LIGHT_DT = "#89CFF0";
export const PRIMARY_MAIN_DT = "#89CFF0";
export const PRIMARY_DARK_DT = "#4BE0E6";
export const PRIMARY_CONTRAST_DT = "#ffffff";

export const SECONDARY_LIGHT_DT = "#212121"; // "#273457"
export const SECONDARY_MAIN_DT = "#181818"; // "#161d31"
export const SECONDARY_DARK_DT = "#121212"; // "#0F1422"

export const SECONDARY_CONTRAST_TEXT_DT = "#FFFFFF";

export const DIVIDER_COLOR_DT = GREY_800_DT;

export const INFO_LIGHT_DT = "#4d79ff";
export const INFO_MAIN_DT = "#0073FF";
export const INFO_DARK_DT = "#005eff";
export const INFO_CONTRAST_TEXT_DT = "#ffffff";

export const SUCCESS_LIGHT_DT = "#7ED957";
export const SUCCESS_MAIN_DT = "#7ED957";
export const SUCCESS_DARK_DT = "#7ED957";
export const SUCCESS_CONTRAST_TEXT_DT = "#FFFFFF";

export const WARNING_LIGHT_DT = "#EBB6E5";
export const WARNING_MAIN_DT = "#EBB6E5";
export const WARNING_DARK_DT = "#EBB6E5";
export const WARNING_CONTRAST_DT = "#ffffff";

/* --------------error--------------- */

export const ERROR_LIGHT = "#fccece";
export const ERROR_MAIN = "#FF0000";
export const ERROR_DARK = "#E50000";
export const ERROR_CONTRAST_TEXT = "#ffffff";

export const LIGHT_PINK_GRADIENT =
  "linear-gradient(180deg, #fcf2f4 0%, #ffebe0 100%)";

export const BLUE_GRADIENT = "linear-gradient(180deg, #9fc9c8 0%, #c1f5f3 58%)";

export const ORANGE_GRADIENT =
  "linear-gradient(180deg, #e58b26 0%, #e77133 100%)";

export const WHITE_GRADIENT =
  "linear-gradient(180deg, #ffffff 0%, #ffffff 100%)";

export const BROWN_GRADIENT =
  "linear-gradient(180deg, #c06918 0%, #c06918 100%)";

export const GREEN_GRADIENT =
  "linear-gradient(180deg, #f2faf2 0%, #ECF9EB 100%)";

export const RED_GRADIENT = "linear-gradient(to top, #ea3324 0%, #ec605a 100%)";

export const YELLOW_GRADIENT =
  "linear-gradient(180deg, #FFFEEC 0%, #F9F6AF 100%)";

export const PURPLE_GRADIENT =
  "linear-gradient(180deg, #fabbe9 0%, #fae1f3 100%)";

export const GREY_GRADIENT =
  "linear-gradient(180deg, #f0f1f2 0%, #c3bccc  100%)";

export const BLACK_GRADIENT =
  "linear-gradient(180deg, #333333 0%, #121212 100%)";

/* --------------FONT--------------- */
export const FONT_WEIGHT_REGULAR = 150;
export const FONT_WEIGHT_MEDIUM = 200;
export const FONT_WEIGHT_BOLD = 300;
export const SUBTITLE_FONT_WEIGHT = 500;
export const H1_LINE_HEIGHT = 80 / 64;
export const H1_FONT_SIZE = pxToRem(40);
export const DEFAULT_LINE_HEIGHT = 1;
export const SECONDARY_LINE_HEIGHT = 22 / 14;
export const H2_LINE_HEIGHT = 64 / 48;
export const H2_FONT_SIZE = pxToRem(28);
export const SMALL_FONT_SIZE = pxToRem(18);
export const EXTRA_SMALL_FONT_SIZE = pxToRem(12);
export const BORDER_COLOR = "0.5px solid gray";
export const WHITE_BORDER = "0.5px solid white";
/* ----------------------------- */
export const DEFAULT_TRANSITION = 300;
export const DEFAULT_SPACE = 10;
export const NAVBAR_HEIGHT = 60;
export const SIDEBAR_BUTTON_HEIGHT = 60;

/* ---------------------------- */
export const SIDEBAR_WIDTH = 240;
export const DRAWER_WIDTH = 300;
export const MODAL_WIDTH = "95vw";

export const DEFAULT_MARGIN = 10;
export const MAX_MARGIN = 25;

export const DEFAULT_BORDER_RADIUS = 20;
export const BUTTON_BORDER_RADIUS = 5;
export const ROUND_RADIUS = 50;

export const SMALL_BUTTON_PADDING = 5;
export const MEDIUM_PADDING = 8;
export const PAPPER_PADDING = 15;
export const MAX_PADDING = 40;

export const BUTTON_HEIGHT_LARGE = 50;
export const BUTTON_HEIGHT_MEDIUM = 40;
export const BUTTON_HEIGHT_SMALL = 30;
export const DETAILS_DRAWER_BUTTON_HEIGHT = 54;
export const FILTER_WIDTH = 233;
export const FILTER_PADDING = 12;
export const DEFAULT_BLUR = "blur(8px)";
export const LOGIN_SWIPE_PAPER_HEIGHT = 360;

/* ---------------------------- */
export const HOMEPAGE_SPACE_MD = "30px 100px";
export const HOMEPAGE_SPACE_SM = "30px";
export const HOMEPAGE_SPACE_XS = "15px";

export const BOX_SHADOW =
  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;";

/* prev consts */
export const PAGE_SIZE = 20;

export const DATE_FORMAT = "MMM dd, yyyy";

export const TIME_FORMAT = "hh:mm aa";

export const FULL_DATE_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
