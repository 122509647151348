import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, Box } from "@mui/material";
import { Button } from "@mui/material";

type ChainFaviconKey = 'arb' | 'avax' | 'base' | 'bnb' | 'eth' | 'opt' | 'poly'; // Add all possible keys

const chainfavicons: Record<ChainFaviconKey, string> = {
    arb: '/favicon/arb.svg',
    avax: '/favicon/avax.svg',
    base: '/favicon/base.png',
    bnb: '/favicon/bnb.png',
    eth: '/favicon/eth.png',
    opt: '/favicon/opt.svg',
    poly: './favicon/poly.svg',
};

interface MatrixData {
    rowLabels: string[];
    columnLabels: string[];
    matrix: number[][];
}
// const valueFlowDescription = "Directional flow of value from source to destination blockchain.\n\n Diagonal is Total Value Locked.";
const valueFlowDescription = "CCIP revenue for each source and destination blockchain ( fee + gas cost, in $). \n\n Diagonal is blockchain Total Value Locked.";

const ChainMatrix: React.FC = () => {
    const [data, setData] = useState<MatrixData | null>(null);
    const [infoText, setInfoText] = useState<string>(valueFlowDescription);

    useEffect(() => {
        fetch('/valueFlow.json')
            .then((response) => response.json())
            .then((matrixData: MatrixData) => {
                setData(matrixData);
            })
            .catch((error) => {
                console.error('Error fetching matrix data:', error);
            });
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    const loadData = (fileName: string, text: string) => {
        fetch(`/${fileName}`)
            .then((response) => response.json())
            .then((matrixData: MatrixData) => {
                setData(matrixData);
                setInfoText(text);
            })
            .catch((error) => {
                console.error('Error fetching matrix data:', error);
            });
    };

    return (
        <Box>

            <Grid container justifyContent="flex-start" alignItems="center" spacing={{ xs: 0, sm: 0, md: 0 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={0}>
                            <Button
                                // fullWidth // Makes the button expand to the full width of the Grid item
                                sx={{ backgroundColor: '#2E2E2E', '&:hover': { backgroundColor: '#434343' } }}
                                variant="contained"
                                onClick={() => loadData('valueFlow.json', valueFlowDescription)}>
                                CrossChain Revenue
                            </Button>
                        </Grid>
                        <Grid item xs={0}>
                            <Button
                                // fullWidth // Makes the button expand to the full width of the Grid item
                                sx={{ backgroundColor: '#2E2E2E', '&:hover': { backgroundColor: '#434343' } }}
                                variant="contained"
                                onClick={() => loadData('laneSecurity.json', "Theoretic staking value needed to secure this lane, assuming superlinear staking.")}>
                                Economic Security
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography color="textPrimary" sx={{ mt: 2 }}>
                            {infoText}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <Box position="relative">
                        {/* This Typography is positioned in relation to the Box it's inside of */}
                        <Typography variant="h4" color="textPrimary" sx={{
                            position: 'absolute',
                            top: '60%',
                            left: '-100px', // Adjust as needed based on the width of the "Source" text
                            transform: 'translateY(-50%) rotate(-90deg)',
                            // whiteSpace: 'nowrap', // Ensures that the text doesn't wrap
                        }}>
                            Source
                        </Typography>

                        <Typography variant="h4" color="textPrimary" sx={{ mb: 1 }} align="center">
                            Destination
                        </Typography>

                        <TableContainer component={Paper} elevation={2} sx={{ width: '100%', }}>
                            <Table aria-label="chain matrix" sx={{ minWidth: 0, minHeight: 0 }}>
                                <TableHead>
                                    <TableRow>
                                        {data.columnLabels.map((columnLabel, index) => (
                                            <TableCell key={index} align="center" sx={{ color: 'white', fontSize: '20px', }}>
                                                {columnLabel}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.rowLabels.map((rowLabel, rowIndex) => (
                                        <TableRow key={rowLabel}>
                                            <TableCell component="th" scope="row" sx={{ color: 'white', fontSize: '20px', }}>
                                                {rowLabel}
                                            </TableCell>
                                            {data.matrix[rowIndex].map((value, columnIndex) => (
                                                <TableCell key={`${rowLabel}-${data.columnLabels[columnIndex]}`} align="center" sx={{
                                                    color: value > 1 ? '#89CFF0' : '#555', fontSize: '20px', // Light blue for text
                                                    // backgroundColor: value > 1 ? 'rgba(0, 0, 139, 0.1)' : undefined // Dark blue background with some transparency
                                                }}>

                                                    {rowIndex === columnIndex ? (
                                                        <>
                                                            <img src={chainfavicons[rowLabel as keyof typeof chainfavicons]} alt={`${rowLabel} logo`}
                                                                style={{ width: '20px', height: '20px', verticalAlign: 'middle', marginRight: '5px' }} />
                                                            {value}
                                                        </>
                                                    ) : (
                                                        `${value}`
                                                    )}

                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    );
};

export default ChainMatrix;
