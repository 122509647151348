import React from 'react';
import AppProvider from "./contexts/AppProvider";
import MuiThemeConfigProvider from "./contexts/MuiThemeConfigProvider";
import ThemeProvider from "./contexts/ThemeContext";
import HomePage from "./pages/HomePage";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // or { Routes } for newer versions

// import FAQPage from './pages/faq'; // Adjust the path according to your directory structure
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import HomePage from './pages/HomePage';
import FAQPage from './pages/faq';
import SignalsPage from './pages/signals';
import EntitiesPage from './pages/entities';
import { app } from './utils/firebaseComponents';
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
const analytics = getAnalytics(app);

export default function App() {

  return (
    <Router>
      <div style={{ overflowY: 'auto', height: '100vh' }}>
        <ThemeProvider>
          <MuiThemeConfigProvider>
            <AppProvider>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/signals" element={<SignalsPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/entities" element={<EntitiesPage />} />

              </Routes>
            </AppProvider>
          </MuiThemeConfigProvider>
        </ThemeProvider>
      </div>
    </Router>
  );
}