import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Stack,
  Box,
  styled,

} from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Scatter, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Legend, Cell } from 'recharts';
import MatrixComponent from '../components/chainMatrix';
import LineViz from '../components/lineviz';
// import MatrixComponent from '../components/chorddiagram';
import SankeyComponent from '../components/sankey';
import ServiceCards from '../components/serviceCards';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip as TextTooltip, Button } from '@mui/material';


const StyledContainer = styled(Stack)({
  // height: "200%",
  minHeight: "100vh",
  width: "100%",
  margin: 0,
  padding: 10,
  overflow: "auto",
  alignItems: "center",
});



export default function HomePage() {
  const [dailyPayData, setDailyPayData] = useState<Array<any>>([]);
  const [topNumbers, setTopNumbers] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    fetch('/top_numbers.json')
      .then(response => response.json())
      .then(data => setTopNumbers(data));
  }, []);

  return (
    <StyledContainer>

      <Box sx={{ width: '90%', marginTop: '30px', marginBottom: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Stack for the Title */}
        <Box sx={{ flexGrow: 1 }}></Box>
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ flexGrow: 2 }}>
          <Typography variant="h1" color="textPrimary" textAlign="center">
            CCIP live
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="center" alignItems="center">
          <Link to="/entities" style={{}}>
            <Button
              variant="text"
              sx={{
                color: 'white',
                fontSize: '1.1em',
                marginLeft: '0px',
                marginRight: '0px',
              }}
            >
              DataTable
            </Button></Link>
          <Link to="/faq" style={{}}>
            <Button
              variant="text"
              sx={{
                color: 'white',
                fontSize: '1.1em',
                marginLeft: '0px',
                marginRight: '0px',
              }}
            >
              FAQ
            </Button></Link>

        </Stack>

      </Box>


      <Stack direction="row" spacing={3} marginBottom={1}>
        {topNumbers && Object.entries(topNumbers).map(([key, value], index) => (
          <Box key={index} sx={{ flexGrow: 1, p: 2, borderRadius: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ textShadow: `0 0 0px ${['#ff6600', '#00aaff', '#434343'][index]}` }}>
              {key}
            </Typography>
            <Typography variant="h4" sx={{ textShadow: `0 0 8px ${['#ff6600', '#00aaff', '#434343'][index]}` }}>
              {(value as string)}
            </Typography>
          </Box>

        ))}
      </Stack>

      <LineViz dataUrl={"/daily_pay.csv"} width={0} />


      <Typography variant="h4" color="textPrimary" align="center" style={{ marginTop: '100px', marginBottom: '100px' }}>
        Protocols
      </Typography>
      {/* <SankeyComponent jsonFile={"/services/dfx/flow.json"} /> */}
      <ServiceCards />


      <Typography variant="h4" color="textPrimary" gutterBottom align="center" style={{ marginTop: '100px', marginBottom: '100px' }}>
        Cross-Chain
      </Typography>

      <MatrixComponent />

      <Typography variant="h4" color="textPrimary" align="center" style={{ marginTop: '100px', marginBottom: '100px' }}>

      </Typography>

      <Typography >
        supported networks: all CCIP protocols as of Dec 2023. Updated weekly.
      </Typography>
      {/* <Typography >
        all numbers are work in progress, do not make decisions using them.
      </Typography> */}

      <Typography >
        <br />
        ... and link the world.
      </Typography>
      <Typography >
        𝕏 = @Postulate5
      </Typography>

    </StyledContainer>
  );
}
