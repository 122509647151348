import { alpha } from "@mui/material";
import { IPalette } from "../types/mui";
import {
  BLACK,
  DIVIDER_COLOR_DT,
  DIVIDER_COLOR_LT,
  ERROR_CONTRAST_TEXT,
  ERROR_DARK,
  ERROR_LIGHT,
  ERROR_MAIN,
  GREY_100_DT,
  GREY_100_LT,
  GREY_200_DT,
  GREY_200_LT,
  GREY_300_DT,
  GREY_300_LT,
  GREY_400_DT,
  GREY_400_LT,
  GREY_500_DT,
  GREY_500_LT,
  GREY_50_DT,
  GREY_50_LT,
  GREY_600_DT,
  GREY_600_LT,
  GREY_700_DT,
  GREY_700_LT,
  GREY_800_DT,
  GREY_800_LT,
  GREY_900_DT,
  GREY_900_LT,
  GREY_A100_DT,
  GREY_A100_LT,
  GREY_A200_DT,
  GREY_A200_LT,
  GREY_A400_DT,
  GREY_A400_LT,
  GREY_A700_DT,
  GREY_A700_LT,
  INFO_CONTRAST_TEXT_DT,
  INFO_CONTRAST_TEXT_LT,
  INFO_DARK_DT,
  INFO_DARK_LT,
  INFO_LIGHT_DT,
  INFO_LIGHT_LT,
  INFO_MAIN_DT,
  INFO_MAIN_LT,
  PRIMARY_CONTRAST_DT,
  PRIMARY_CONTRAST_LT,
  PRIMARY_DARK_DT,
  PRIMARY_DARK_LT,
  PRIMARY_LIGHT_DT,
  PRIMARY_LIGHT_LT,
  PRIMARY_MAIN_DT,
  PRIMARY_MAIN_LT,
  SECONDARY_CONTRAST_TEXT_DT,
  SECONDARY_CONTRAST_TEXT_LT,
  SECONDARY_DARK_DT,
  SECONDARY_DARK_LT,
  SECONDARY_LIGHT_DT,
  SECONDARY_LIGHT_LT,
  SECONDARY_MAIN_DT,
  SECONDARY_MAIN_LT,
  SUCCESS_CONTRAST_TEXT_DT,
  SUCCESS_CONTRAST_TEXT_LT,
  SUCCESS_DARK_DT,
  SUCCESS_DARK_LT,
  SUCCESS_LIGHT_DT,
  SUCCESS_LIGHT_LT,
  SUCCESS_MAIN_DT,
  SUCCESS_MAIN_LT,
  TEXT_DISABLED_DT,
  TEXT_DISABLED_LT,
  TEXT_PRIMARY_DT,
  TEXT_PRIMARY_LT,
  TEXT_SECONDARY_DT,
  TEXT_SECONDARY_LT,
  WARNING_CONTRAST_DT,
  WARNING_CONTRAST_LT,
  WARNING_DARK_DT,
  WARNING_DARK_LT,
  WARNING_LIGHT_DT,
  WARNING_LIGHT_LT,
  WARNING_MAIN_DT,
  WARNING_MAIN_LT,
  WHITE
} from "./THEME";

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

/* ====================>LIGHT Theme<==================== */
const GREY_LT = {
  50: GREY_50_LT,
  100: GREY_100_LT,
  200: GREY_200_LT,
  300: GREY_300_LT,
  400: GREY_400_LT,
  500: GREY_500_LT,
  600: GREY_600_LT,
  700: GREY_700_LT,
  800: GREY_800_LT,
  900: GREY_900_LT,
  A100: GREY_A100_LT,
  A200: GREY_A200_LT,
  A400: GREY_A400_LT,
  A700: GREY_A700_LT,
};

const PRIMARY_LT = {
  light: PRIMARY_LIGHT_LT,
  main: PRIMARY_MAIN_LT,
  dark: PRIMARY_DARK_LT,
  contrastText: PRIMARY_CONTRAST_LT,
};

const SECONDARY_LT = {
  light: SECONDARY_LIGHT_LT,
  main: SECONDARY_MAIN_LT,
  dark: SECONDARY_DARK_LT,
  contrastText: SECONDARY_CONTRAST_TEXT_LT,
};

const INFO_LT = {
  light: INFO_LIGHT_LT,
  main: INFO_MAIN_LT,
  dark: INFO_DARK_LT,
  contrastText: INFO_CONTRAST_TEXT_LT,
};
const SUCCESS_LT = {
  light: SUCCESS_LIGHT_LT,
  main: SUCCESS_MAIN_LT,
  dark: SUCCESS_DARK_LT,
  contrastText: SUCCESS_CONTRAST_TEXT_LT,
};
const WARNING_LT = {
  light: WARNING_LIGHT_LT,
  main: WARNING_MAIN_LT,
  dark: WARNING_DARK_LT,
  contrastText: WARNING_CONTRAST_LT,
};
const ERROR_LT = {
  light: ERROR_LIGHT,
  main: ERROR_MAIN,
  dark: ERROR_DARK,
  contrastText: ERROR_CONTRAST_TEXT,
};

const GRADIENTS_LT = {
  primary: createGradient(alpha(WARNING_LT.light, 0.1), PRIMARY_LT.main),
  info: createGradient(INFO_LT.light, INFO_LT.main),
  success: createGradient(SUCCESS_LT.light, SUCCESS_LT.main),
  warning: createGradient(WARNING_LT.light, WARNING_LT.main),
  error: createGradient(ERROR_LT.light, ERROR_LT.main),
};

/* ====================>DARK Theme<==================== */
const GREY_DT = {
  50: GREY_50_DT,
  100: GREY_100_DT,
  200: GREY_200_DT,
  300: GREY_300_DT,
  400: GREY_400_DT,
  500: GREY_500_DT,
  600: GREY_600_DT,
  700: GREY_700_DT,
  800: GREY_800_DT,
  900: GREY_900_DT,
  A100: GREY_A100_DT,
  A200: GREY_A200_DT,
  A400: GREY_A400_DT,
  A700: GREY_A700_DT,
};

const PRIMARY_DT = {
  light: PRIMARY_LIGHT_DT,
  main: PRIMARY_MAIN_DT,
  dark: PRIMARY_DARK_DT,
  contrastText: PRIMARY_CONTRAST_DT,
};

const SECONDARY_DT = {
  light: SECONDARY_LIGHT_DT,
  main: SECONDARY_MAIN_DT,
  dark: SECONDARY_DARK_DT,
  contrastText: SECONDARY_CONTRAST_TEXT_DT,
};

const INFO_DT = {
  light: INFO_LIGHT_DT,
  main: INFO_MAIN_DT,
  dark: INFO_DARK_DT,
  contrastText: INFO_CONTRAST_TEXT_DT,
};
const SUCCESS_DT = {
  light: SUCCESS_LIGHT_DT,
  main: SUCCESS_MAIN_DT,
  dark: SUCCESS_DARK_DT,
  contrastText: SUCCESS_CONTRAST_TEXT_DT,
};
const WARNING_DT = {
  light: WARNING_LIGHT_DT,
  main: WARNING_MAIN_DT,
  dark: WARNING_DARK_DT,
  contrastText: WARNING_CONTRAST_DT,
};
const ERROR_DT = {
  light: ERROR_LIGHT,
  main: ERROR_MAIN,
  dark: ERROR_DARK,
  contrastText: ERROR_CONTRAST_TEXT,
};

const GRADIENTS_DT = {
  primary: createGradient(alpha(PRIMARY_DT.light, 0.1), PRIMARY_DT.main),
  info: createGradient(INFO_DT.light, INFO_DT.main),
  success: createGradient(SUCCESS_DT.light, SUCCESS_DT.main),
  warning: createGradient(WARNING_DT.light, WARNING_DT.main),
  error: createGradient(ERROR_DT.light, ERROR_DT.main),
};

/* ===========================> Palette LIGHT THEME <========================== */

export const LIGHT_PALETTE: IPalette = {
  common: { black: BLACK, white: WHITE },
  primary: PRIMARY_LT,
  secondary: SECONDARY_LT,
  info: INFO_LT,
  success: SUCCESS_LT,
  warning: WARNING_LT,
  error: ERROR_LT,
  grey: GREY_LT,
  gradients: GRADIENTS_LT,
  divider: DIVIDER_COLOR_LT,
  text: {
    primary: TEXT_PRIMARY_LT,
    secondary: TEXT_SECONDARY_LT,
    disabled: TEXT_DISABLED_LT,
  },
  background: {
    paper: SECONDARY_DARK_LT,
    default: SECONDARY_MAIN_LT,
    light: SECONDARY_LIGHT_LT,
  },
  action: {
    active: GREY_LT[600],
    hover: GREY_LT["A100"],
    selected: GREY_LT["A200"],
    disabled: GREY_LT["A700"],
    disabledBackground: GREY_LT["A400"],
    focus: GREY_LT["A200"],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

/* ===========================> Palette DARK THEME <========================== */

export const DARK_PALETTE: IPalette = {
  common: { black: BLACK, white: GREY_400_DT },
  primary: PRIMARY_DT,
  secondary: SECONDARY_DT,
  info: INFO_DT,
  success: SUCCESS_DT,
  warning: WARNING_DT,
  error: ERROR_DT,
  grey: GREY_DT,
  gradients: GRADIENTS_DT,
  divider: DIVIDER_COLOR_DT,
  text: {
    primary: TEXT_PRIMARY_DT,
    secondary: TEXT_SECONDARY_DT,
    disabled: TEXT_DISABLED_DT,
  },
  background: {
    paper: SECONDARY_DARK_DT,
    default: SECONDARY_MAIN_DT,
    light: SECONDARY_LIGHT_DT,
  },
  action: {
    active: GREY_DT[300],
    hover: GREY_DT["A100"],
    selected: GREY_DT["A200"],
    disabled: TEXT_DISABLED_DT,
    disabledBackground: GREY_DT["A400"],
    focus: GREY_DT["A200"],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};
