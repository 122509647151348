
import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { LineChart, XAxis, YAxis, Tooltip, Line, ResponsiveContainer, Legend } from 'recharts';
import { Stack } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useTheme } from '@mui/material/styles';

const ChartContainer = styled(Stack)({
    flexDirection: 'row',
    width: '100%',
    height: '100%',
});
interface LineVizProps {
    dataUrl: string;
    width?: number;
    height?: number;
}

const LineViz: React.FC<LineVizProps> = ({ dataUrl, width, height = 400 }) => {
    const [dailyPayData, setDailyPayData] = useState<Array<any>>([]);

    useEffect(() => {
        fetch(dataUrl)
            .then(response => response.text())
            .then(text => {
                const parsed = Papa.parse(text, { header: true });
                setDailyPayData(parsed.data);
            });
    }, [dataUrl]);
    const theme = useTheme();

    var use_dot = false;
    if (width == 0) {
        use_dot = true;
    }
    return (
        <ChartContainer>
            <ResponsiveContainer width="100%" height={height}>
                <LineChart data={dailyPayData}>
                    <defs>
                        <filter id="glow">
                            <feGaussianBlur stdDeviation="5" result="coloredBlur" />
                            <feMerge>
                                <feMergeNode in="coloredBlur" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    <Legend layout="vertical" verticalAlign="top" align="right" />
                    <XAxis dataKey="day" interval={30} style={{
                        // stroke: 'gray',
                        // fill: 'gray' //theme.palette.background.default,
                    }} />

                    <YAxis type="number" domain={[0, 4000]} allowDataOverflow={true} hide={true}
                        style={{
                            stroke: theme.palette.background.default,
                            fill: theme.palette.background.default,
                        }} />
                    <Tooltip
                        formatter={(value, name, props) => {
                            if (name === "value transferred $" && value <= 0) {
                                return [];
                            }

                            const { payload } = props;
                            let style = {};
                            switch (name) {
                                case "value transferred $":
                                    const squaredValue = Math.pow(
                                        typeof value === 'number' ? value :
                                            (Array.isArray(value) ? 0 : parseFloat(value || '0') / 10),
                                        2
                                    );

                                    style = { color: "#FFC0CB" };
                                    return [<span style={style}>{`${name}: ${squaredValue.toFixed(2)}`}</span>];  // Adjusted to display squared value
                                case "messages":
                                    return [<span style={style}>{`${name}: ${value}`}</span>];
                                case "ccip revenue $":
                                    style = { color: "#89CFF0" };
                                    return [<span style={style}>{`${name}: ${value}`}</span>];
                                default:
                                    return [];
                            }
                        }}
                        labelFormatter={(label) => `${label}`}
                        contentStyle={{ backgroundColor: '#2E2E2E', border: '1px solid #2E2E2E' }}
                        itemStyle={{ color: '#FFF' }}

                    />


                    <Line
                        type="natural"
                        dataKey="ccip revenue $"
                        stroke="#89CFF0" //"#8884d8" 
                        strokeWidth={2}
                        isAnimationActive={false}
                        filter="url(#glow)"
                        dot={false}
                    />

                    <Line
                        type="monotone"
                        dataKey="messages"
                        stroke="#434343"
                        strokeWidth={2}
                        isAnimationActive={false}
                        dot={false}
                    // filter="url(#glow)"
                    />
                    <Line
                        type="natural"
                        dataKey="value transferred $"
                        stroke="#FFC0CB" //"#8884d8"
                        strokeWidth={width}
                        isAnimationActive={false}
                        filter="url(#glow)"
                        dot={use_dot}
                        fill='#FFC0CB'
                    // connectNulls={true}
                    // label={({ value }) => value > 0 ? value : ''}
                    />



                </LineChart>
            </ResponsiveContainer>

        </ChartContainer>
    );
};

export default LineViz;
