import React, { useState, createContext } from "react";

interface IProps {
  children: React.ReactNode;
}

type TAppContext = [boolean, (arg: boolean) => void];

export const AppContext = createContext<TAppContext>([
  true,
  () => { },
]);

export default function AppProvider({ children }: IProps) {
  // Replace useLocalStorageState with useState
  const [expandedView, setExpandedView] = useState(false);

  // Provide a tuple that matches TAppContext
  const value: TAppContext = [expandedView, setExpandedView];

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
