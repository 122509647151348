import React, { useState } from 'react';
import { Button, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Box, useTheme } from '@mui/material';

// Define an interface for the profile object
interface Profile {
    picture: string;
    name: string;
    text: string;
    backtext: string;
    blockchain: string;
    status: string;

    blockchain2: string;
    status2: string;

    blockchain3: string;
    status3: string;
}

interface MultiActionAreaCardProps {
    profile: Profile;
    onStatusClick: (blockchain: string) => void;
}

const ProfileCard: React.FC<MultiActionAreaCardProps> = ({ profile, onStatusClick }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [imageError, setImageError] = useState(false);
    const theme = useTheme();

    const handleCardClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <Card
            sx={{
                color: 'white',
                border: `1px solid ${theme.palette.primary.main}`, // Primary color border
                boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.6)',
                '&:hover': {
                    boxShadow: `0 0 8px 5px ${theme.palette.primary.main}`, // Glow effect on hover
                },
                position: 'relative',
                overflow: 'hidden',
                height: 300, // Increased height
                transition: 'box-shadow 0.1s ease-in-out',
            }}
        >
            <CardActionArea onClick={handleCardClick}>
                <CardMedia
                    component="img"
                    image={imageError ? '/path/to/placeholder.jpg' : profile.picture}
                    alt={profile.name}
                    onError={handleImageError}
                    sx={{ height: '40%', objectFit: 'cover', width: '40%' }} // Adjust height to 30% and cover to fit/cut the image
                />
                <CardContent>
                    {!isExpanded ? (
                        <Typography variant="body2" color="white">
                            {profile.text}
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="white">
                            {profile.backtext}
                        </Typography>
                    )}

                </CardContent>

            </CardActionArea>
            <Button size="small" color="primary" onClick={() => onStatusClick(profile.blockchain)}>
                {profile.status}
            </Button>
            <Button size="small" color="primary" onClick={() => onStatusClick(profile.blockchain2)}>
                {profile.status2}
            </Button>
            <Button size="small" color="primary" onClick={() => onStatusClick(profile.blockchain3)}>
                {profile.status3}
            </Button>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px', }}> */}

            {/* </Box> */}
        </Card>
    );
};

const InstitutionCards: React.FC = () => {
    const profiles = [
        {
            name: "ANZ",
            picture: "/institutions/anz.png", // Replace with actual image path
            text: "ANZ (Australia and New Zealand Banking Group) is a multinational banking and financial services company headquartered in Melbourne, Australia. It's known for offering a range of banking and financial products to personal, business, and institutional clients across the globe.",
            backtext: "ANZ is leading CCIP integration to facilitate secure and efficient cross-chain transactions with its A$DC, SG$DC stablecoins, and carbon credits. This initiative focuses on enhancing interoperability across various blockchain networks, streamlining financial transactions and broadening the scope of blockchain applications in the banking sector.",
            blockchain: "https://ccip.chain.link/msg/0x0dfbb1f33951b0f8073be6eb16932882ba233fa663a579b9799d77d6050570c5",
            status: "SG$DC",
            blockchain2: "https://ccip.chain.link/msg/0xfbe97719abbbf33cab8821a3963692ef4298ae1338ef724f6511210fa4b3e33c",
            status2: "A$DC",
            blockchain3: "https://sepolia.etherscan.io/address/0x195ba6656f44516273a0302795bf90adc8a1a0da",
            status3: "CarbonMultiToken",
        },
        {
            name: "Swift",
            picture: "/institutions/swift.png", // Replace with actual image path
            text: "Swift is a global member-owned cooperative and the world’s leading provider of secure financial messaging services. Headquartered in Belgium, Swift facilitates international money and security transfers, serving as a crucial player in the global financial ecosystem.",
            backtext: "SWIFT aims to expand its infrastructure to facilitate the transfer of tokenized assets across public and private ledgers. This initiative, involving collaboration with major financial institutions, is seen as a significant move towards integrating traditional banking infrastructure with blockchain technology, potentially increasing liquidity in the private markets and expanding the blockchain industry's reach in the global financial ecosystem..",
            blockchain: "",
            status: "",
            blockchain2: "",
            status2: "",
            blockchain3: "",
            status3: "",
        },
        {
            name: "DTCC",
            picture: "/institutions/dtcc.png", // Replace with actual image path
            text: "Depository Trust & Clearing Corporation provides clearing and settlement services. DTCC plays a critical role in ensuring the stability and integrity of the global financial markets by reducing risk, enhancing transparency, and driving efficiency.",
            backtext: "DTCC is integrating blockchain technology into existing financial systems using CCIP, focusing on the secure transfer of tokenized assets across various blockchain networks. This initiative seeks to enhance operational efficiency, liquidity, and transparency in capital markets, positioning DTCC at the forefront of innovation in financial market infrastructure.",
            blockchain: "",
            status: "",
            blockchain2: "",
            status2: "",
            blockchain3: "",
            status3: "",
        },
        {
            name: "Euroclear",
            picture: "/institutions/euroclear.png", // Replace with actual image path
            text: "Euroclear is a Belgium-based financial services company specializing in the settlement of securities transactions and the safekeeping and asset servicing of these securities. It is a critical part of the European financial market, offering post-trade services covering bonds, funds, equities, and derivatives.",
            backtext: "Euroclear is a collaborating member of SWIFT's experiment with CCIP.",
            blockchain: "",
            status: "",
            blockchain2: "",
            status2: "",
            blockchain3: "",
            status3: "",
        }
    ];

    const handleStatusClick = (blockchainUrl: string) => {
        window.open(blockchainUrl, '_blank'); // Opens the URL in a new tab
    };

    return (
        <Grid container spacing={2}>
            {profiles.map((profile) => (
                <Grid item xs={12} sm={6} lg={3} key={profile.name}>
                    <ProfileCard profile={profile} onStatusClick={handleStatusClick} />
                </Grid>
            ))}
        </Grid>
    );
};

export default InstitutionCards;
