import React, { useState, useEffect, Suspense } from 'react';
import { LineChart, XAxis, YAxis, Tooltip, Line, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import Papa from 'papaparse';
import { Card, CardContent, Typography, Box, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import WebIcon from '@mui/icons-material/Web';

const SankeyComponent = React.lazy(() => import('../components/sankey'));
const LineViz = React.lazy(() => import('../components/lineviz'));


interface Service {
    name: string;
    favicon: string;
    texts: {
        protocol_type: string;
        website: string;
        summary: string;
    };
    flowData: string;
    timeSeriesData: string;
}


const serviceNames = ['synthetix', 'dfx', 'raft'];

const loadServiceData = async (): Promise<Service[]> => {
    const services: Service[] = [];

    for (const serviceName of serviceNames) {
        const flowData = `/services/${serviceName}/flow.json`;
        const timeSeriesData = `/services/${serviceName}/daily_ts.csv`;
        const favicon = `/services/${serviceName}/favicon.png`; // Example path

        const textsPath = `/services/${serviceName}/texts.json`;
        // Fetch the JSON file and parse it
        const response = await fetch(textsPath);
        const texts = await response.json(); // Assuming texts.json has a simple key-value structure


        services.push({
            name: serviceName,
            favicon,
            texts,
            flowData,
            timeSeriesData,
        });
    }

    return services;
}

const ServiceCards = () => {
    const [services, setServices] = useState<Service[]>([]);
    const [selectedService, setSelectedService] = useState<string>('');
    const theme = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            const loadedServices = await loadServiceData();
            setServices(loadedServices);
            if (loadedServices.length > 0) {
                setSelectedService(loadedServices[0].name); // Default to the first service
            }
        };

        fetchData();
    }, []);

    const handleServiceChange = (event: SelectChangeEvent<string>) => {
        setSelectedService(event.target.value as string);
    };

    const selectedServiceData = services.find(service => service.name === selectedService);

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            {/* Service Selector */}
            <Box width="20%" padding={10}>
                <Select
                    value={selectedService}
                    onChange={handleServiceChange}
                    fullWidth
                >
                    {services.map((service, index) => (
                        <MenuItem key={index} value={service.name}>{service.name}</MenuItem>
                    ))}
                </Select>
            </Box>

            {/* Service Details */}
            <Box width="70%">
                {selectedServiceData && (
                    <Card sx={{ marginBottom: 2, backgroundColor: theme.palette.background.default }}>
                        <CardContent>
                            {/* Top Row */}
                            <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2}>
                                <img src={selectedServiceData.favicon} alt={`${selectedServiceData.name} logo`} style={{ width: '50px', height: '50px' }} />
                                <Typography variant="h5" color="textPrimary" marginLeft={2}>{selectedServiceData.name}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Box width="60%" paddingRight={1}>
                                    <Typography variant="h5" color="textSecondary">{selectedServiceData?.texts.protocol_type}</Typography>
                                    <Box height="1rem" />
                                    <Typography variant="body1" color="textSecondary">{selectedServiceData?.texts.summary}</Typography>

                                    <Link href={selectedServiceData.texts.website} target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <WebIcon />
                                        </IconButton>
                                    </Link>

                                </Box>
                                <Box width="30%" paddingLeft={1}>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <SankeyComponent jsonFile={selectedServiceData.flowData} />
                                    </Suspense>
                                </Box>
                            </Box>

                            <Box height="50%" marginTop={2}>
                                <LineViz height={200} dataUrl={selectedServiceData.timeSeriesData} width={2} />
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </div>
    );
};

export default ServiceCards;
