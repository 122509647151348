// faq.tsx
import React from 'react';
import { Typography, Stack, Box, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // If you're using React Router v6

const FAQPage = () => {
    const navigate = useNavigate(); // For React Router v6
    // const history = useHistory(); // Uncomment this line for React Router v5 and earlier

    const handleBack = () => {
        navigate(-1); // For React Router v6
        // history.goBack(); // Uncomment this line for React Router v5 and earlier
    };

    const faqData = [
        {
            question: "What is CCIP?",
            answer: (
                <span>
                    Cross-Chain Interoperability Protocol (CCIP) is the standard, defined by Chainlink Labs, for decentralized data infrastructure connecting public and private ledgers. In CCIP, value and instruction, moves together across chains.
                </span>
            )
        },
        {
            question: "How does CCIP Billing work?",
            answer: (
                <span>
                    In Chainlink's
                    <a href="https://docs.chain.link/ccip/billing" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }} > official billing documentation</a>,
                    the billing model is provided without numeric values. This is probably because CCIP billing is lane specific, and exact costs are still being explored at this early stage of the lifecycle.<br />
                    From the documentation:
                    <ul>
                        <li><strong>Destination gas overhead:</strong> This is the average overhead incurred on the destination chain by CCIP to process the message and/or token transfer.</li>
                        <li><strong>Flat network premium for messages:</strong> This fee is a static amount that users pay when sending cross-chain messages.</li>
                        <li><strong><span style={{ color: '#89CFF0' }}>Basis point</span>  based premium for tokens:</strong> This is a percentage-based fee that users pay for cross-chain token transfers.
                            It is calculated using the following expression: tokenAmount x price x <span style={{ color: '#89CFF0' }}>bps ratio</span> . price is initially denominated in USD and translated into the feeToken.</li>
                    </ul>
                    The premium portion of fees paid in alternative assets will have a surcharge of 10% when compared to LINK payments.
                    <br></br><br></br>


                    CCIP Revenue in ccip.live is referenced from events emitted by protocol contract, not calculated.

                    <img src="./ccip_billing.svg" alt="CCIP Logo" style={{ marginTop: '10px' }} />

                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        Token Transfer Fee
                    </Typography>

                    Where is the bps ratio found, and compensation structure to CCIP operators for token transfers? Examine events of the CCIP on-ramp contract.
                    <br></br><br></br>

                    The on-ramp contract config log contains the billing parameters.
                    <br></br><br></br>

                    For example, in the
                    <a href="https://snowtrace.io/tx/0x6e93fca2ae05d815b2861142dad54a6877cc2ee9588347e424020e1b2302849f#eventlog" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }} >Avalanche on-ramp configuration event</a>,
                    "tokentransferFeeConfigSet" is a log entry listing parameters for token transfer payment. You can decode hex fields as numbers, except the third field is an address.
                    By clicking "View Source Code", and searching for tokentransferFeeConfigSet, the contract ABI is

                    <pre style={{ padding: '10px', borderRadius: '5px' }}>
                        <code>
                            {`{
    "anonymous": false,
    "inputs": [
        {
            "components": [
                {
                    "internalType": "address",
                    "name": "token",
                    "type": "address"
                },
                {
                    "internalType": "uint32",
                    "name": "minFee",
                    "type": "uint32"
                },
                {
                    "internalType": "uint32",
                    "name": "maxFee",
                    "type": "uint32"
                },
                {
                    "internalType": "uint16",
                    "name": "ratio",
                    "type": "uint16"
                }
            ],
            "indexed": false,
            "internalType": "struct EVM2EVMOnRamp.TokenTransferFeeConfigArgs[]",
            "name": "transferFeeConfig",
            "type": "tuple[]"
        }
    ],
    "name": "TokenTransferFeeConfigSet",
    "type": "event"
},`}
                        </code>
                    </pre>

                    Among the 6 data fields in that log entry, because "transferFeeConfig" is an array, we ignore the first two fields "32" and "2" (reasons).
                    Supplementing with
                    <a href="https://github.com/code-423n4/2023-05-chainlink/blob/main/contracts/onRamp/EVM2EVMOnRamp.sol#L121" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }} >CCIP source code</a>
                    :
                    <ul>
                        <li><strong>address token</strong>: Token address that following parameters apply to.</li>
                        <li><strong>uint32 minFee</strong>: Minimum USD fee to charge, multiples of 1 US cent, or 0.01USD. For a value of 100, this is $1. </li>
                        <li><strong>uint32 maxFee</strong>: Maximum USD fee to charge. For a value of "100000000", this is $1 million.</li>
                        <li> <span style={{ color: '#89CFF0' }}><strong>uint16 ratio</strong></span>: Ratio of token transfer value to charge as fee, multiples of 0.1bps, or 10e-5. For a value of "100", this is 10bps, or <span style={{ color: '#89CFF0' }}>0.1%</span> </li>
                    </ul>
                    The token transfer policy is in the decision tree above.
                    Notice only the link token's address, and policy, is set for this on-ramp.
                    For a feetoken like wrapped avax, Chainlink's official billing model describes a surcharge of 10%.
                    Taking fees in wavax for token transfer is work in progress.
                    <br></br><br></br>

                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        Message Fee and Destination Gas
                    </Typography>


                    To check the message fee and destination gas parameters, return to the on-ramp log events and ctr-f "FeeConfigSet".
                    Like tokentransfer config set, the overall input is an array so ignore the first 2 data fields. Remaining data fields specify fee policies for the link token (on avax) and wrapped avax.
                    Try decoding from the third field onwards.
                    <br></br><br></br>
                    Contract ABI

                    <pre style={{ padding: '10px', borderRadius: '5px' }}>
                        <code>
                            {`
                                "anonymous": false,
                            "inputs": [
                            {
                                "components": [
                            {
                                "internalType": "address",
                            "name": "token",
                            "type": "address"
                    },
                            {
                                "internalType": "uint64",
                            "name": "gasMultiplier",
                            "type": "uint64"
                    },
                            {
                                "internalType": "uint96",
                            "name": "networkFeeAmountUSD",
                            "type": "uint96"
                    },
                            {
                                "internalType": "uint32",
                            "name": "destGasOverhead",
                            "type": "uint32"
                    },
                            {
                                "internalType": "uint16",
                            "name": "destGasPerPayloadByte",
                            "type": "uint16"
                    },
                            {
                                "internalType": "bool",
                            "name": "enabled",
                            "type": "bool"
                    }
                            ],
                            "indexed": false,
                            "internalType": "struct EVM2EVMOnRamp.FeeTokenConfigArgs[]",
                            "name": "feeConfig",
                            "type": "tuple[]"
            }
                            ],
                            "name": "FeeConfigSet",
                            "type": "event"
    },`}
                        </code>
                    </pre>

                    We see that "networkFeeAmountUSD" under the address "0x594..." (chainlink's contract) has a value of 450000000000000000.
                    "networkFeeAmountUSD" under the address "0xb31..." (wavax's contract) has a value of 500000000000000000.
                    In EVMs, converting from the default, smallest denomination is multiplying that value by 1e-18.
                    Thus for a message, it costs $0.45 paying in link, or $0.5 paying in non-link feeToken.

                    <br></br><br></br>

                    "gasMultiplier" after 1e-18 conversion is 1.05. CCIP charges 5% higher gas costs on destination chain, in anticipation of price spikes.

                    <br></br><br></br>
                    These policies hold for all on-ramp contracts inspected, as of Nov 2023.


                </span>
            )

        },
        // Add more FAQs here
    ];


    return (

        <Stack sx={{ padding: 4 }}>
            <Button
                variant="text"
                onClick={handleBack}
                // sx={{ marginBottom: 2 }}
                sx={{
                    color: 'white',
                    fontSize: '1.1em',
                    marginLeft: '0px', // Adjust as needed for spacing
                    marginRight: '0px',
                }}
            >
                Go Back
            </Button>

            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Frequently Asked Questions
            </Typography>
            {faqData.map((faq, index) => (
                <Box key={index} sx={{ marginBottom: 2 }}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6">{faq.question}</Typography>
                        <Typography component="div">
                            {typeof faq.answer === 'string' ? faq.answer : faq.answer}
                        </Typography>
                    </Paper>
                </Box>
            ))}

        </Stack>
    );
};

export default FAQPage;
