/* eslint-disable @typescript-eslint/no-empty-interface */

import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { useContext } from "react";

import { DARK_PALETTE, LIGHT_PALETTE } from "../constants/MUI_PALETTE";
import SHAPE from "../constants/MUI_SHAPE";
import { IPalette } from "../types/mui";

import { ThemeContext } from "./ThemeContext";


declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
  interface Palette extends IPalette { }
}

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export default function MuiThemeConfigProvider({ children }: IProps) {
  const { darkModeEnabled } = useContext(ThemeContext);

  const theme: Theme = createTheme({
    typography: {
      fontFamily: 'Roboto Mono, monospace',
    },
    palette: {
      ...(darkModeEnabled
        ? {
          mode: "dark",
          ...DARK_PALETTE,
        }
        : {
          mode: "light",
          ...LIGHT_PALETTE,
        }),
    },

    shape: SHAPE,
  });

  return (
    <StyledEngineProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
