import { initializeApp } from "firebase/app";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

import { getAuth } from 'firebase/auth';
import 'firebase/compat/firestore';

import { getFirestore, collection, addDoc, doc, setDoc, FirestoreSettings } from 'firebase/firestore';
// import { getDatabase, ref, set, child, push } from 'firebase/database';
import * as firebaseui from 'firebaseui';

import { query, onSnapshot, orderBy, deleteDoc } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export const firebaseConfig = {
  apiKey: "AIzaSyCH4hniesv-tmAOtemiJlz9b8zgt_rhuJE",
  authDomain: "ccip-live.firebaseapp.com",
  projectId: "ccip-live",
  storageBucket: "ccip-live.appspot.com",
  messagingSenderId: "396562693440",
  appId: "1:396562693440:web:3395f34c27726f85434ea5",
  measurementId: "G-5FYVWX8LKJ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// export const performance = getPerformance(app);
const auth = getAuth(app);
export const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);


// export const AuthComponent = () => {
//   useEffect(() => {
//     const auth = getAuth(app);

//     const uiConfig = {
//       signInSuccessUrl: 'signed-in',
//       signInOptions: [
//         firebase.auth.EmailAuthProvider.PROVIDER_ID,
//         // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//         // Add more providers as needed
//       ],
//     };

//     // Check if there is an existing FirebaseUI instance and reuse it, otherwise create a new one
//     const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

//     ui.start('#firebaseui-auth-container', uiConfig);

//     return () => {
//       ui.reset();
//     };
//   }, []);

//   return (
//     <div>
//     <div id= "firebaseui-auth-container" > </div>
//     < /div>
//   );
// };

// export const MonitorComponent = () => {
//   const auth = getAuth(app);
//   const [user] = useAuthState(auth);
//   const [address, setAddress] = useState('');
//   const [selectedOption, setSelectedOption] = useState('S/R');

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     if (!user) {
//       // Only add to the database if the user is authenticated
//       return;
//     }

//     const newAddress = {
//       email: user.email,
//       address_str: address,
//       notification_rule: selectedOption,

//       timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//       watchset: 'default',
//       metadata: 'default'
//     };

//     const db = getFirestore(app);

//     await setDoc(doc(db, `users/${user.uid}/watch`,
//       newAddress.address_str),
//       newAddress);
//     setAddress('');
//   };

//   const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setAddress(event.target.value);
//   };


//   const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedOption(event.target.value);
//   };
//   return (
//     <div>
//       {user && (
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             name="inputField"
//             value={address}
//             onChange={handleAddressChange}
//             placeholder="address to monitor"
//           />
//           <div>
//             <label>
//               <input
//                 type="radio"
//                 name="options"
//                 value="S"
//                 checked={selectedOption === 'S'}
//                 onChange={handleOptionChange}
//               />
//               Send ERC20 ( {'>'} 0.1Ξ )
//             </label>
//           </div>
//           <div>
//             <label>
//               <input
//                 type="radio"
//                 name="options"
//                 value="R"
//                 checked={selectedOption === 'R'}
//                 onChange={handleOptionChange}
//               />
//               Receive ERC20 ( {'>'} 0.1Ξ )
//             </label>
//           </div>
//           <div>
//             <label>
//               <input
//                 type="radio"
//                 name="options"
//                 value="S/R"
//                 checked={selectedOption === 'S/R'}
//                 onChange={handleOptionChange}
//               />
//               S/R ERC20 ( {'>'} 0.1Ξ )
//             </label>
//           </div>
//           <button type="submit">Submit</button>
//         </form>
//       )}
//     </div>
//   );
// };

// export const MonitoringList = () => {
//   const auth = getAuth(app);
//   const [user] = useAuthState(auth);


//   const firestore = getFirestore(app);
//   const monitorRef = collection(firestore, `users/${user?.uid}/watch`);
//   const q = query(monitorRef, orderBy('__name__')); // You can change the orderBy parameter based on your needs
//   const [documents, loading, error] = useCollectionData(q);

//   if (!user) {
//     // Only add to the database if the user is authenticated
//     return <div>please sign in </div>
//   }

//   const handleDelete = async (docId: string) => {
//     // console.log('docId:', docId)
//     const docRef = doc(firestore, `users/${user.uid}/watch`, docId);
//     await deleteDoc(docRef);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   } else

//     return (
//       <div>
//         <ul style={{ listStyleType: 'none', padding: 0 }}>
//           {documents?.map((doc) => (
//             <li key={doc.address_str}>
//               {doc.notification_rule} {doc.address_str}
//               <button onClick={() => handleDelete(doc.address_str)}>x</button>
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
// };