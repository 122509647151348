import React from 'react';
import Button from '@mui/material/Button';

interface CopyButtonProps {
    text: string;
}


const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {

    const handleCopy = async () => {

        try {
            await navigator.clipboard.writeText(text);
            console.log('Text copied to clipboard');
        } catch (err) {
            console.error('Error in copy operation: ', err);
        }
    };

    return (
        <Button variant="outlined" onClick={handleCopy}>
            Copy
        </Button>
    );
};

export default CopyButton;
