import { createContext, useState } from "react";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export const ThemeContext = createContext({
  darkModeEnabled: true,
  toggleDarkModeEnabled: () => {},
});

export default function ThemeProvider({ children }: IProps) {
  const [darkModeEnabled, setDarkModeEnabled] = useState(true);

  const toggleDarkModeEnabled = () => {
    setDarkModeEnabled(!darkModeEnabled);
  };

  return (
    <ThemeContext.Provider
      value={{
        darkModeEnabled,
        toggleDarkModeEnabled,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
